.header {
  height: 75px;
}

.title {
  font-size: 30px;
}

.title-color {
  color: #27cce0;
}

.navigation-item:hover {
  color: #27cce0;
}

@media screen and (max-width: 900px) {
  .title {
    font-size: 20px;
  }
}

@media screen and (max-width: 673px) {
  .title {
    font-size: 15px;
  }
}
