.home {
  display: inline-block;
}

.divider {
  height: 1px;
  background-color: #27cce0;
}

.home-welcome-title {
  font-family: "LTEnergy", "serif";
}

.top-content-block {
  height: auto;
}

.content-block {
  background-color: rgb(27, 29, 30);
  border-radius: 20px;
}

.home-loading-block {
  height: 1000px;
}

.home-profit-graph {
  margin-left: -25px;
}

.data-table {
  color: white !important;
}

.data-table:hover {
  color: white !important;
}

.table-striped {
  color: white !important;
}

td {
  width: 170px;
}

.current-profit-row {
  width: 170px;
}