.color-yellow {
  color: #ffd200;
}
.color-dark-yellow {
  color: #ffb200;
}
.color-light-yellow {
  color: #ffe57d;
}
.color-green {
  color: #7bd657;
}
.color-lime {
  color: #ccff51;
}
.color-dark-green {
  color: #2fb500;
}
.color-blue {
  color: #5ddbff;
}
.color-dark-blue {
  color: #1868c2;
}
.color-pink {
  color: #f252b8;
}
.color-dark-pink {
  color: #c21b85;
}
.color-purple {
  color: #b45ce7;
}
.color-dark-purple {
  color: #7925aa;
}
.color-darker-purple {
  color: #500d77;
}
.color-purple-alt {
  color: #9240c2;
}
.color-light-purple {
  color: #d291f8;
}
.color-red {
  color: #fb5d5d;
}
.color-light-red {
  color: #fecfcf;
}
.color-dark-red {
  color: #e02d2d;
}
.color-orange {
  color: #ff9600;
}
.color-dark-orange {
  color: #d34f00;
}
.color-grey {
  color: #d1d1d1;
}
.color-darker-grey {
  color: #575756;
}
.color-alt-grey {
  color: #e3e3e3;
}
.color-light-grey {
  color: #ededed;
}
.color-light-grey-alt {
  color: #4a4a49;
}
.color-dark-grey {
  color: #575756;
}
.color-dark-grey-alt {
  color: #4a4a49;
}
.color-black {
  color: #000000;
}
.color-white {
  color: #ffffff;
}
.color-blue-grey {
  color: #6280a1;
}
.color-dark-blue-grey {
  color: #3d5672;
}
.color-cyan {
  color: #1ee2bf;
}
.color-brown {
  color: #ad856f;
}
.color-beige {
  color: #f4d9c1;
}
.color-extra-dark-alt {
  color: #3c3c3b;
}
.color-grey-alt-alt {
  color: #b2b2b2;
}
.color-main-color {
  color: #b45757;
}
.color-main-color-darker {
  color: #4a2323;
}
.background-color-dark-yellow {
  background-color: #ffb200;
}
.background-color-yellow {
  background-color: #ffd200;
}
.background-color-light-yellow {
  background-color: #ffe57d;
}
.background-color-green {
  background-color: #7bd657;
}
.background-color-lime {
  background-color: #ccff51;
}
.background-color-dark-green {
  background-color: #2fb500;
}
.background-color-blue {
  background-color: #5ddbff;
}
.background-color-dark-blue {
  background-color: #1868c2;
}
.background-color-pink {
  background-color: #f252b8;
}
.background-color-dark-pink {
  background-color: #c21b85;
}
.background-color-purple {
  background-color: #b45ce7;
}
.background-color-dark-purple {
  background-color: #7925aa;
}
.background-color-darker-purple {
  background-color: #500d77;
}
.background-color-purple-alt {
  background-color: #9240c2;
}
.background-color-light-purple {
  background-color: #d291f8;
}
.background-color-red {
  background-color: #fb5d5d;
}
.background-color-light-red {
  background-color: #fecfcf;
}
.background-color-dark-red {
  background-color: #e02d2d;
}
.background-color-orange {
  background-color: #ff9600;
}
.background-color-dark-orange {
  background-color: #d34f00;
}
.background-color-grey {
  background-color: #d1d1d1;
}
.background-color-darker-grey {
  background-color: #575756;
}
.background-color-alt-grey {
  background-color: #e3e3e3;
}
.background-color-light-grey {
  background-color: #ededed;
}
.background-color-light-grey-alt {
  background-color: #4a4a49;
}
.background-color-dark-grey {
  background-color: #575756;
}
.background-color-dark-grey-alt {
  background-color: #4a4a49;
}
.background-color-black {
  background-color: #000000;
}
.background-color-white {
  background-color: #ffffff;
}
.background-color-blue-grey {
  background-color: #6280a1;
}
.background-color-dark-blue-grey {
  background-color: #3d5672;
}
.background-color-cyan {
  background-color: #1ee2bf;
}
.background-color-brown {
  background-color: #ad856f;
}
.background-color-beige {
  background-color: #f4d9c1;
}
.background-color-extra-dark-alt {
  background-color: #3c3c3b;
}
.background-color-grey-alt-alt {
  background-color: #b2b2b2;
}
.background-color-main-color {
  background-color: #b45757;
}
.background-color-main-color-darker {
  background-color: #4a2323;
}

.flex-direction-row {
  flex-direction: row;
  display: flex;
}
.flex-direction-row-reverse {
  flex-direction: row-reverse;
  display: flex;
}
.flex-direction-column {
  flex-direction: column;
  display: flex;
}
.flex-direction-column-reverse {
  flex-direction: column-reverse;
  display: flex;
}
.flex-justify-content-start {
  justify-content: flex-start;
  display: flex;
}
.flex-justify-content-center {
  justify-content: center;
  display: flex;
}
.flex-justify-content-end {
  justify-content: flex-end;
  display: flex;
}
.flex-justify-content-space-around {
  justify-content: space-around;
  display: flex;
}
.flex-justify-content-space-between {
  justify-content: space-between;
  display: flex;
}
.flex-align-items-start {
  align-items: flex-start;
  display: flex;
}
.flex-align-items-center {
  align-items: center;
  display: flex;
}
.flex-align-items-stretch {
  align-items: stretch;
  display: flex;
}
.flex-align-items-end {
  align-items: flex-end;
  display: flex;
}
.flex-align-content-start {
  align-content: flex-start;
  display: flex;
}
.flex-align-content-center {
  align-content: center;
  display: flex;
}
.flex-align-content-end {
  align-content: flex-end;
  display: flex;
}
.flex-align-content-space-around {
  align-content: space-around;
  display: flex;
}
.flex-align-content-space-between {
  align-content: space-between;
  display: flex;
}
.flex-align-self-stretch {
  align-self: stretch;
  display: flex;
}
.flex-align-self-center {
  align-self: center;
  display: flex;
}
.flex-align-self-flex-start {
  align-self: flex-start;
  display: flex;
}
.flex-align-self-flex-end {
  align-self: flex-end;
  display: flex;
}
.flex-no-wrap {
  flex-wrap: nowrap;
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-1 {
  flex: 1;
  min-width: 0;
}
.flex-2 {
  flex: 2;
  min-width: 0;
}
.flex-3 {
  flex: 3;
  min-width: 0;
}
.flex-4 {
  flex: 4;
  min-width: 0;
}
.flex-5 {
  flex: 5;
  min-width: 0;
}
.flex-6 {
  flex: 6;
  min-width: 0;
}
.flex-7 {
  flex: 7;
  min-width: 0;
}
.flex-8 {
  flex: 8;
  min-width: 0;
}
.flex-9 {
  flex: 9;
  min-width: 0;
}
.flex-10 {
  flex: 10;
  min-width: 0;
}
.flex-11 {
  flex: 11;
  min-width: 0;
}
.flex-12 {
  flex: 12;
  min-width: 0;
}

.flex-allow-grow {
  flex-grow: 1;
  flex-basis: auto;
  flex-shrink: 0;
  display: flex;
}
.flex-allow-shrink {
  flex-grow: 0;
  flex-basis: auto;
  flex-shrink: 1;
  display: flex;
}
.flex-allow-grow-shrink {
  flex-grow: 1;
  flex-basis: auto;
  flex-shrink: 1;
  display: flex;
}
.flex-block-grow-shrink {
  flex-grow: 0;
  flex-basis: auto;
  flex-shrink: 0;
  display: flex;
}
.pt-0,
.py-0,
.p-0 {
  padding-top: 0;
}
.pt-0-5,
.py-0-5,
.p-0-5 {
  padding-top: 4px;
}
.pt-1,
.py-1,
.p-1 {
  padding-top: 8px;
}
.pt-1-5,
.py-1-5,
.p-1-5 {
  padding-top: 12px;
}
.pt-2,
.py-2,
.p-2 {
  padding-top: 16px;
}
.pt-2-5,
.py-2-5,
.p-2-5 {
  padding-top: 20px;
}
.pt-3,
.py-3,
.p-3 {
  padding-top: 24px;
}
.pt-3-5,
.py-3-5,
.p-3-5 {
  padding-top: 28px;
}
.pt-4,
.py-4,
.p-4 {
  padding-top: 32px;
}
.pt-4-5,
.py-4-5,
.p-4-5 {
  padding-top: 36px;
}
.pt-5,
.py-5,
.p-5 {
  padding-top: 40px;
}
.pt-5-5,
.py-5-5,
.p-5-5 {
  padding-top: 44px;
}

.pl-0,
.px-0,
.p-0 {
  padding-left: 0;
}
.pl-0-5,
.px-0-5,
.p-0-5 {
  padding-left: 4px;
}
.pl-1,
.px-1,
.p-1 {
  padding-left: 8px;
}
.pl-1-5,
.px-1-5,
.p-1-5 {
  padding-left: 12px;
}
.pl-2,
.px-2,
.p-2 {
  padding-left: 16px;
}
.pl-2-5,
.px-2-5,
.p-2-5 {
  padding-left: 20px;
}
.pl-3,
.px-3,
.p-3 {
  padding-left: 24px;
}
.pl-3-5,
.px-3-5,
.p-3-5 {
  padding-left: 28px;
}
.pl-4,
.px-4,
.p-4 {
  padding-left: 32px;
}
.pl-4-5,
.px-4-5,
.p-4-5 {
  padding-left: 36px;
}
.pl-5,
.px-5,
.p-5 {
  padding-left: 40px;
}
.pl-5-5,
.px-5-5,
.p-5-5 {
  padding-left: 44px;
}

.pb-0,
.py-0,
.p-0 {
  padding-bottom: 0;
}
.pb-0-5,
.py-0-5,
.p-0-5 {
  padding-bottom: 4px;
}
.pb-1,
.py-1,
.p-1 {
  padding-bottom: 8px;
}
.pb-1-5,
.py-1-5,
.p-1-5 {
  padding-bottom: 12px;
}
.pb-2,
.py-2,
.p-2 {
  padding-bottom: 16px;
}
.pb-2-5,
.py-2-5,
.p-2-5 {
  padding-bottom: 20px;
}
.pb-3,
.py-3,
.p-3 {
  padding-bottom: 24px;
}
.pb-3-5,
.py-3-5,
.p-3-5 {
  padding-bottom: 28px;
}
.pb-4,
.py-4,
.p-4 {
  padding-bottom: 32px;
}
.pb-4-5,
.py-4-5,
.p-4-5 {
  padding-bottom: 36px;
}
.pb-5,
.py-5,
.p-5 {
  padding-bottom: 40px;
}
.pb-5-5,
.py-5-5,
.p-5-5 {
  padding-bottom: 44px;
}

.pr-0,
.px-0,
.p-0 {
  padding-right: 0;
}
.pr-0-5,
.px-0-5,
.p-0-5 {
  padding-right: 4px;
}
.pr-1,
.px-1,
.p-1 {
  padding-right: 8px;
}
.pr-1-5,
.px-1-5,
.p-1-5 {
  padding-right: 12px;
}
.pr-2,
.px-2,
.p-2 {
  padding-right: 16px;
}
.pr-2-5,
.px-2-5,
.p-2-5 {
  padding-right: 20px;
}
.pr-3,
.px-3,
.p-3 {
  padding-right: 24px;
}
.pr-3-5,
.px-3-5,
.p-3-5 {
  padding-right: 28px;
}
.pr-4,
.px-4,
.p-4 {
  padding-right: 32px;
}
.pr-4-5,
.px-4-5,
.p-4-5 {
  padding-right: 36px;
}
.pr-5,
.px-5,
.p-5 {
  padding-right: 40px;
}
.pr-5-5,
.px-5-5,
.p-5-5 {
  padding-right: 44px;
}

.mt-0,
.my-0,
.m-0 {
  margin-top: 0;
}
.mt-0-5,
.my-0-5,
.m-0-5 {
  margin-top: 4px;
}
.mt-1,
.my-1,
.m-1 {
  margin-top: 8px;
}
.mt-1-5,
.my-1-5,
.m-1-5 {
  margin-top: 12px;
}
.mt-2,
.my-2,
.m-2 {
  margin-top: 16px;
}
.mt-2-5,
.my-2-5,
.m-2-5 {
  margin-top: 20px;
}
.mt-3,
.my-3,
.m-3 {
  margin-top: 24px;
}
.mt-3-5,
.my-3-5,
.m-3-5 {
  margin-top: 28px;
}
.mt-4,
.my-4,
.m-4 {
  margin-top: 32px;
}
.mt-4-5,
.my-4-5,
.m-4-5 {
  margin-top: 36px;
}
.mt-5,
.my-5,
.m-5 {
  margin-top: 40px;
}
.mt-5-5,
.my-5-5,
.m-5-5 {
  margin-top: 44px;
}

.ml-0,
.mx-0,
.m-0 {
  margin-left: 0;
}
.ml-0-5,
.mx-0-5,
.m-0-5 {
  margin-left: 4px;
}
.ml-1,
.mx-1,
.m-1 {
  margin-left: 8px;
}
.ml-1-5,
.mx-1-5,
.m-1-5 {
  margin-left: 12px;
}
.ml-2,
.mx-2,
.m-2 {
  margin-left: 16px;
}
.ml-2-5,
.mx-2-5,
.m-2-5 {
  margin-left: 20px;
}
.ml-3,
.mx-3,
.m-3 {
  margin-left: 24px;
}
.ml-3-5,
.mx-3-5,
.m-3-5 {
  margin-left: 28px;
}
.ml-4,
.mx-4,
.m-4 {
  margin-left: 32px;
}
.ml-4-5,
.mx-4-5,
.m-4-5 {
  margin-left: 36px;
}
.ml-5,
.mx-5,
.m-5 {
  margin-left: 40px;
}
.ml-5-5,
.mx-5-5,
.m-5-5 {
  margin-left: 44px;
}

.mb-0,
.my-0,
.m-0 {
  margin-bottom: 0;
}
.mb-0-5,
.my-0-5,
.m-0-5 {
  margin-bottom: 4px;
}
.mb-1,
.my-1,
.m-1 {
  margin-bottom: 8px;
}
.mb-1-5,
.my-1-5,
.m-1-5 {
  margin-bottom: 12px;
}
.mb-2,
.my-2,
.m-2 {
  margin-bottom: 16px;
}
.mb-2-5,
.my-2-5,
.m-2-5 {
  margin-bottom: 20px;
}
.mb-3,
.my-3,
.m-3 {
  margin-bottom: 24px;
}
.mb-3-5,
.my-3-5,
.m-3-5 {
  margin-bottom: 28px;
}
.mb-4,
.my-4,
.m-4 {
  margin-bottom: 32px;
}
.mb-4-5,
.my-4-5,
.m-4-5 {
  margin-bottom: 36px;
}
.mb-5,
.my-5,
.m-5 {
  margin-bottom: 40px;
}
.mb-5-5,
.my-5-5,
.m-5-5 {
  margin-bottom: 44px;
}

.mr-0,
.mx-0,
.m-0 {
  margin-right: 0;
}
.mr-0-5,
.mx-0-5,
.m-0-5 {
  margin-right: 4px;
}
.mr-1,
.mx-1,
.m-1 {
  margin-right: 8px;
}
.mr-1-5,
.mx-1-5,
.m-1-5 {
  margin-right: 12px;
}
.mr-2,
.mx-2,
.m-2 {
  margin-right: 16px;
}
.mr-2-5,
.mx-2-5,
.m-2-5 {
  margin-right: 20px;
}
.mr-3,
.mx-3,
.m-3 {
  margin-right: 24px;
}
.mr-3-5,
.mx-3-5,
.m-3-5 {
  margin-right: 28px;
}
.mr-4,
.mx-4,
.m-4 {
  margin-right: 32px;
}
.mr-4-5,
.mx-4-5,
.m-4-5 {
  margin-right: 36px;
}
.mr-5,
.mx-5,
.m-5 {
  margin-right: 40px;
}
.mr-5-5,
.mx-5-5,
.m-5-5 {
  margin-right: 44px;
}

.f-0 {
  font-size: 8px;
}
.f-0-5 {
  font-size: 10px;
}
.f-1 {
  font-size: 12px;
}
.f-1-5 {
  font-size: 14px;
}
.f-2 {
  font-size: 16px;
}
.f-2-5 {
  font-size: 18px;
}
.f-3 {
  font-size: 20px;
}
.f-3-5 {
  font-size: 22px;
}
.f-4 {
  font-size: 24px;
}
.f-4-5 {
  font-size: 26px;
}
.f-5 {
  font-size: 28px;
}
.f-5-5 {
  font-size: 30px;
}
.f-6 {
  font-size: 32px;
}
.f-6-5 {
  font-size: 34px;
}
.f-7 {
  font-size: 36px;
}
.f-7-5 {
  font-size: 38px;
}
.f-8 {
  font-size: 40px;
}
.f-8-5 {
  font-size: 46px;
}
.f-9 {
  font-size: 48px;
}
.f-9-5 {
  font-size: 52px;
}
.f-10 {
  font-size: 56px;
}

.w-100 {
  width: 100%;
}
.w-50 {
  width: 50%;
}

.text-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

body {
  font-family: "LTEnergy", "serif";
}

a {
  text-decoration: none;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  color: white;
}

.background-color {
  background-color: rgb(24, 26, 27); /*#0d111f;*/
}

.background-color-darker {
  background-color: #06080f;
}

.background-color-lighter {
  background-color: rgb(27, 29, 30);
}

.content-block {
  background-color: #06080f;
  border-radius: 20px;
}

.full-border-radius {
  border-radius: 100%;
}

.color-background {
  color: #0d111f;
}

.color-background-darker {
  color: #06080f;
}
