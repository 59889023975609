.pincode-input {
  width: 50px;
  height: 50px;
  outline: none;
  border: 0px solid;
}

.login-content {
  height: 1000px;
}
